<template>
  <div>
    <addEquipos :item="equipoEditable" :pathFB="pathFB"/>
    <list-equipos
      :items="itemsEquipos"
      @edit="modificarEquipo"
      @delete="eliminarEquipo"
      :totalRows="itemsEquipos.length"
    />
  </div>
</template>

<script>
import firebase from 'firebase/app'
import addEquipos from './Components/addEquipos.vue'
import listEquipos from './Components/listEquipos.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'firebase/database'

export default {
  components: {
    addEquipos,
    listEquipos,
  },
  data() {
    return {
      itemsEquipos: [],
      equipoEditable: null,
      pathFB: '',
    }
  },
  mounted() {
    if (this.$route.meta.isBio) {
      this.pathFB = 'calibracionesBio/equipos'
    } else {
      this.pathFB = 'calibraciones/equipos'
    }
    firebase
      .database()
      .ref(this.pathFB)
      .on('value', snapshot => {
        this.itemsEquipos = []
        if (snapshot.exists()) {
          Object.entries(snapshot.val()).forEach(([key, value]) => {
            value.key = key
            this.itemsEquipos.push(value)
          })
        }
      })
  },
  methods: {
    modificarEquipo(event) {
      this.equipoEditable = event
    },
    eliminarEquipo(event) {
      this.$bvModal
        .msgBoxConfirm('Por favor confirmar que desea eliminar el equipo.', {
          title: 'Confirmar',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Confirmar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            firebase
              .database()
              .ref(`${this.pathFB}/${event}`)
              .remove()
              .then(response => {
                console.log(response)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Equipo Eliminado.',
                    icon: 'TrashIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
}
</script>
