<template>
  <b-card>
    <b-row>
      <b-col
        md="6"
        sm="6"
        class="my-1"
      >
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-1">Paginación</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Filtrar"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 searchInput items-center"          
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar..."
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                variant="gradient-primary"
                @click="filter = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      class="capitalize text-center"
      striped
      hover
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-text="No se encontrarón resultados"
      @filtered="onFiltered"
    >
        <template #cell(ene)="data">
            {{data.item.tiempos[0].times}}
        </template>
        <template #cell(feb)="data">
            {{data.item.tiempos[1].times}}
        </template>
        <template #cell(mar)="data">
            {{data.item.tiempos[2].times}}
        </template>
        <template #cell(abr)="data">
            {{data.item.tiempos[3].times}}
        </template>
        <template #cell(may)="data">
            {{data.item.tiempos[4].times}}
        </template>
        <template #cell(jun)="data">
            {{data.item.tiempos[5].times}}
        </template>
        <template #cell(jul)="data">
            {{data.item.tiempos[6].times}}
        </template>
        <template #cell(ago)="data">
            {{data.item.tiempos[7].times}}
        </template>
        <template #cell(sep)="data">
            {{data.item.tiempos[8].times}}
        </template>
        <template #cell(oct)="data">
            {{data.item.tiempos[9].times}}
        </template>
        <template #cell(nov)="data">
            {{data.item.tiempos[10].times}}
        </template>
        <template #cell(dic)="data">
            {{data.item.tiempos[11].times}}
        </template>
      <template #cell(Acciones)="data">
        <b-button
          variant="gradient-primary"
          class="btn-icon rounded-circle"
          @click="editItem(data.item)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          v-if="
            $route.meta.currentUserRol == 'master' ||
              $route.meta.currentUserRol == 'lab_admin'
          "
          variant="danger"
          class="btn-icon rounded-circle ml-0.5"
          @click="deleteItem(data.item.key)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>
    </b-table>
    <b-row>
        <b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="rowsCount ? rowsCount : totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
            />
        </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCol,
  BRow,
  BFormGroup,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  props: {
    items: Array,
    totalRows: Number,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10, 15, 20, 25, 50, 100],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'codigo', label: 'Código' },
        { key: 'denominacion', label: 'Denominación', sortable: true },
        { key: 'ubicacion', label: 'Ubicación', sortable: true },
        { key: 'tipoCalibracion', label: 'I/E', sortable: true },
        { key: 'ene', label: 'Ene.'},
        { key: 'feb', label: 'Feb.'},
        { key: 'mar', label: 'Mar.'},
        { key: 'abr', label: 'Abr.'},
        { key: 'may', label: 'May.'},
        { key: 'jun', label: 'Jun.'},
        { key: 'jul', label: 'Jul.'},
        { key: 'ago', label: 'Ago.'},
        { key: 'sep', label: 'Sep.'},
        { key: 'oct', label: 'Oct.'},        
        { key: 'nov', label: 'Nov.'},
        { key: 'dic', label: 'Dic.'},
        'Acciones',
      ],
      rowsCount: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.rowsCount = filteredItems.length
      this.currentPage = 1
    },
    deleteItem(item) {
      this.$emit('delete', item)
    },
    editItem(item) {
      this.$emit('edit', item)
    },
  },
}
</script>
