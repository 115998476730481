<template>
    <b-card class="flex">
        <b-row>
            <b-col md="4">
                <b-form-group
                    label="Denominación"
                    label-for="denominacion"
                >
                    <b-form-input
                        id="denominacion"
                        placeholder="Denominación"
                        v-model="equipo.denominacion"
                    />
                </b-form-group>
                <b-form-group
                    label="Código de Identificación"
                    label-for="codigo"
                >
                    <b-form-input
                        id="codigo"
                        placeholder="Código de Identificación"
                        v-model="equipo.codigo"
                    />
                </b-form-group>
                <b-form-group
                    label="Calibración Interna/Externa"
                    label-for="calibracion"
                >
                    <v-select
                    placeholder="Calibración Interna/Externa"        
                    id="tipoCalibracion"
                    :options="tipoCalibracion"
                    v-model="equipo.tipoCalibracion"
                    />
                </b-form-group>
                <b-form-group
                    label="Ubicación"
                    label-for="ubicacion"
                >
                    <!-- <v-select                    
                    placeholder="Ubicación"        
                    id="ubicacion"
                    :options="ubicaciones"
                    v-model="equipo.ubicacion"
                    /> -->
                    <b-form-input
                        id="ubicacion"
                        placeholder="Ubicación"
                        v-model="equipo.ubicacion"
                    />
                </b-form-group>
                <div class="flex gap-4">
                    <b-button variant="gradient-primary" class="mt-1 w-1/2" @click="pushData">{{equipo.key ? 'Guardar Cambios': 'Agregar Equipo'}}</b-button>
                    <b-button variant="gradient-danger" class="mt-1 w-1/2" @click="resetInputs()">Cancelar</b-button>
                </div>
            </b-col>
            <b-col md="8" class="flex flex-wrap gap-4 justify-between">
                <template v-for="(mes, k) in meses">
                    <b-form-group :key="k" :label="meses[k].text" class="singleMonth">
                        <b-form-radio-group                    
                            v-model="meses[k].times"
                            :options="options"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                        />
                    </b-form-group>
                </template>
            </b-col>            
        </b-row>                       
    </b-card>
</template>

<script>
import vSelect from 'vue-select'
import {BCard,BFormInput, BFormGroup, BButton,BFormRadioGroup,BCol,BRow } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebase from 'firebase/app'
import 'firebase/database'

function initialState(){
    return {
            equipo: {
                denominacion: '',
                codigo: '',
                tipoCalibracion: '',
                ubicacion: '',
                tiempos: null
            },
            tipoCalibracion: ['Interna', 'Externa'],
            meses: {
                0: {
                    text: 'Enero',
                    times: 0
                },
                1: {
                    text: 'Febrero',
                    times: 0,
                },
                2: {
                    text: 'Marzo',
                    times: 0
                },
                3: {
                    text: 'Abril',
                    times: 0
                },
                4: {
                    text: 'Mayo',
                    times: 0
                },
                5: {
                    text: 'Junio',
                    times: 0
                },
                6: {
                    text: 'Julio',
                    times: 0
                },
                7: {
                    text: 'Agosto',
                    times: 0
                },
                8: {
                    text: 'Septiembre',
                    times: 0
                },
                9: {
                    text: 'Octubre',
                    times: 0
                },
                10: {
                    text: 'Noviembre',
                    times: 0
                },
                11: {
                    text: 'Diciembre',
                    times: 0
                },
            },
            options: [
                { item: '0', name: 'Ninguno' },
                { item: '1', name: '1' },
                { item: '2', name: '2' },
            ],
            ubicaciones: ['Jugo Alcalizado','Cabezal de Calderas'],
            editMode: false
        }
}

export default {
    components:{
        BCard,BFormInput, BFormGroup, BButton, vSelect, BFormRadioGroup, BCol, BRow
    },
    data(){        
        return initialState();
    },
    methods:{
        pushData(){
            this.equipo.tiempos = this.meses
            if(!this.editMode){
                firebase.database().ref(this.pathFB).push(this.equipo)
            }else{
                const tmpKey = this.equipo.key
                delete this.equipo.key
                firebase.database().ref(this.pathFB +'/'+tmpKey).update(this.equipo)
            }
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.editMode ? 'Cambios Guardados' : 'Equipo Agregado',
                    icon: this.editMode ? 'SaveIcon' : 'PlusIcon',
                    variant: 'success',
                }
            })
            Object.assign(this.$data, initialState());
        },
        resetInputs (){
            Object.assign(this.$data, initialState());
        }
    },
    props:{
        item: Object,
        editKey: String,
        pathFB: String,
    },
    watch:{
        item: function (){
            this.equipo = this.item
            this.meses = this.item.tiempos
            this.editMode = true
        }
    }
}
</script>
<style>
fieldset.singleMonth{
    background: #80808030;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.singleMonth legend{
    width: auto;
    background: #fe6f17;
    border-radius: 5px;
    padding: 0 10px;
    color: white;    
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>